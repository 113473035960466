import { SingularityGlobal } from '../../../singulairtyGlobal';
import { SingularityLogger } from '../SingularityLogger';

const SANDBOX_ENV = 'sandbox';
const PRODUCTION_ENV = 'production';
const DEVELOPMENT_ENV = 'qal';

const env = process.env.REACT_APP_ENV || SANDBOX_ENV;
const getTid = () => {
  return SingularityGlobal.tid || "UNKNOWN";
}

const cerebro_prefix = 'https://cerebro.s9y';
let prefix = `${cerebro_prefix}-${DEVELOPMENT_ENV}.com`;

if (env === PRODUCTION_ENV) {
  prefix = `${cerebro_prefix}.gg`;
} else if (env === SANDBOX_ENV) {
  prefix = `${cerebro_prefix}-${SANDBOX_ENV}.gg`;
}
// prefix = 'http://localhost:8080'; //for local server testing


const FETCH_TYPE = 'FETCH';
const GET_DOES_AUTH_USER = 'GET_DOES_AUTH_USER';
const GET_SINGU_TOKEN_BALANCE = 'GET_SINGU_TOKEN_BALANCE';
const GET_SINGU_USER_NFTS = 'GET_SINGU_USER_NFTS';
const GET_SINGU_WEB3_USER_ACCOUNT = 'GET_SINGU__WEB3_USER_ACCOUNT';

const GET_SIGNU_TRANSACTION_STATUS = 'GET_SIGNU_TRANSACTION_STATUS';
const GET_SIGNU_LIVE_PRICE = 'GET_SIGNU_LIVE_PRICE';
const GET_SIGNU_TRANSACTION_STEPS = 'GET_SIGNU_TRANSACTION_STEPS';
const GET_SIGNU_TXN_STATUS_BY_USERID = 'GET_SIGNU_TXN_STATUS_BY_USERID';
const GET_SIGNU_BULK_TXN_STATUS = 'GET_SIGNU_BULK_TXN_STATUS';
const GET_SIGNU_UPDATE_USER_SELETED_ASSET = 'GET_SIGNU_UPDATE_USER_SELETED_ASSET';
const GET_SIGNU_PAYMENT_OPTIONS = 'GET_SIGNU_PAYMENT_OPTIONS';
const GET_CUSTOM_AUTH_DATA = 'GET_CUSTOM_AUTH_DATA';
const GET_ALL_ASSETS = 'GET_ALL_ASSETS';
const UPDATE_TXN_STATUS = 'UPDATE_TXN_STATUS';
const GET_COUNTRY_CODE = 'GET_COUNTRY_CODE';
const GET_MOONPAY_SIGNATURE = 'GET_MOONPAY_SIGNATURE';
const GET_TRANSACTIONS_REWARDS = 'GET_TRANSACTIONS_REWARDS';
const GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS = 'GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS';
const GET_NFTS_FOR_SUPPORTED_CHAINS = 'GET_NFTS_FOR_SUPPORTED_CHAINS';


const API_GET_BALANCES_ROUTE = `${prefix}/v1/sdk-protected/get_token_balance_from_address`;//done
const API_GET_WEB3_USER_ROUTE = `${prefix}/v1/external-wallet-protected/get_create_singularity_user`;//done
const API_GET_NFT_ROUTE = `${prefix}/v1/sdk-protected/get_singularity_user_nfts`;//done
const API_POST_TRANSACTION_STATUS_ROUTE = `${prefix}/v1/external-wallet-protected/txnStatusById`;//done
const API_POST_GET_LIVE_PRICE = `${prefix}/v1/singularity-transactions-protected/fetchLivePrice`;//done
const API_POST_GET_TRANSACTION_STEPS = `${prefix}/v1/singularity-transactions-protected/get_transaction_steps`;//done
const API_POST_TXN_STATUS_BY_USERID = `${prefix}/v1/external-wallet-protected/txnStatusByUserId`;//done
const API_POST_BULK_TXN_STATUS = `${prefix}/v1/external-wallet-protected/bulkTxnStatusId`;//done
const API_POST_UPDATE_USER_SELECTED_ASSET = `${prefix}/v1/singularity-transactions-protected/updateUserSelectedAsset`;//done
const API_GET_PAYMENT_OPTIONS = `${prefix}/v1/sdk-server-protected/get_payment_options`;//done
const API_GET_CUSTOM_AUTH_DATA = `${prefix}/v1/custom-auth/get_custom_auth_data`;//done
const API_DOES_AUTH_USER_EXIST = `${prefix}/v1/sdk-protected/doesAuthUserExist`;//done
const API_GET_ALL_ASSETS = `${prefix}/v1/sdk-protected/getAllAssets`;//done
const API_UPDATE_TXN_STATUS = `${prefix}/v1/singularity-transactions-protected/updateTxnStatus`;//done
const API_GET_USER_COUNTRY_CODE = `${prefix}/v1/external-wallet-protected/country_code`;//done
const API_GET_MOONPAY_SIGNATURE = `${prefix}/v1/external-wallet-protected/moonpay_signature`;//done
const API_GET_TRANSACTION_REWARDS = `${prefix}/v1/external-wallet-protected/fetchRewards`;//done
const API_GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS = `${prefix}/v1/sdk-protected/get_user_token_balance_for_supported_chains`;
const API_GET_NFTS_SUPPORTED_CHAINS = `${prefix}/v1/sdk-protected/get_user_nfts_for_supported_chains`;


// API ERRORS
const MISSING_VALID_CONFIG = 'Missing valid config';
const MISSING_ADDRESS_FIELD = 'Missing address field';

// Wrapper function to add 'tid' header to fetch calls
function fetchWithTid(url, options = {}) {
  // Ensure headers object exists in options
  if (!options.headers) {
    options.headers = {};
  }

  // Add the 'tid' header
  options.headers['tid'] = getTid();

  // Call the original fetch with the modified options
  return fetch(url, options);
}
const fetchWeb3UserAccount = async ({ evmPublicAddress, walletMode, label }, apikey) => {
  try {
    if (!evmPublicAddress || !walletMode) throw new Error(MISSING_VALID_CONFIG);
    let respJson = await fetchWithTid(API_GET_WEB3_USER_ROUTE, {
      method: 'POST',
      body: JSON.stringify({ evmPublicAddress, walletMode, label }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });

    respJson = await respJson.json();
    return respJson;
  } catch (error) {
    console.error(error);
  }
};

const getUserTokenBalances = async (address, apikey, chainId, chainName, chainDetailsId) => {
  //TODO: Make this URL protected and update the headers
  const response = await fetchWithTid(API_GET_BALANCES_ROUTE, {
    method: 'POST',
    body: JSON.stringify({
      chainDetailsId: chainDetailsId,
      chainId: chainId,
      ownerAddress: address,
      chainName: chainName
    }),
    headers: {
      'x-api-key': apikey,
      'Content-Type': 'application/json'
    }
  });
  let result = await response.json();
  return result
};
const fetchSingularityUserNFTs = async (address, chainDetailsId, apikey) => {
  try {
    if (!address) throw new Error(MISSING_ADDRESS_FIELD);
    const response = await fetchWithTid(API_GET_NFT_ROUTE, {
      method: 'POST',
      body: JSON.stringify({ user_address: address, chainDetailsId }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const fetchTransactionRewards = async (transactionId, apikey) => {
  try {
    if (!apikey) throw new Error(MISSING_ADDRESS_FIELD);
    if (!transactionId) throw new Error(MISSING_ADDRESS_FIELD);
    const response = await fetchWithTid(API_GET_TRANSACTION_REWARDS, {
      method: 'POST',
      body: JSON.stringify({ transactionId: transactionId }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};
const fetchTransactionStatus = async (transactionId, apikey) => {
  try {
    if (!transactionId) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_POST_TRANSACTION_STATUS_ROUTE, {
      method: 'POST',
      body: JSON.stringify({ transactionId }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    const transactionStatusResponse = await response.json();
    return transactionStatusResponse;
  } catch (error) {
    console.error(error);
  }
};

const fetchLivePrice = async (
  transactionObject,
  apikey,
  singularityTransactionRequestHash,
  dataJsonString,
  userId
) => {
  try {
    if (!transactionObject) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_POST_GET_LIVE_PRICE, {
      method: 'POST',
      body: JSON.stringify(transactionObject),
      headers: {
        'x-api-key': apikey,
        'x-api-userId': userId,
        'data-json-string': dataJsonString,
        'x-api-signature': singularityTransactionRequestHash,
        'Content-Type': 'application/json'
      }
    });
    const livePriceResponse = await response.json();
    return livePriceResponse;
  } catch (error) {
    console.error(error);
  }
};
const fetchTransactionSteps = async (
  transactionId,
  apikey,
  singularityTransactionRequestHash,
  dataJsonString,
  userId
) => {
  try {
    if (!transactionId) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_POST_GET_TRANSACTION_STEPS, {
      method: 'POST',
      body: JSON.stringify({transactionId}),
      headers: {
        'x-api-key': apikey,
        'x-api-userId': userId,
        'data-json-string': dataJsonString,
        'x-api-signature': singularityTransactionRequestHash,
        'Content-Type': 'application/json'
      }
    });
    const livePriceResponse = await response.json();
    return livePriceResponse;
  } catch (error) {
    console.error(error);
  }
};
const fetchTransactionStatusByUserId = async (userId, apikey, pageNo, pageSize) => {
  try {
    if (!userId) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_POST_TXN_STATUS_BY_USERID +
      '?' +
      new URLSearchParams({
        page: pageNo,
        size: pageSize
      }), {
      method: 'POST',
      body: JSON.stringify({ userId }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json',
        'Priority': 'u=0, i'
      }
    });
    const txnStatusResponse = await response.json();
    return txnStatusResponse;
  } catch (error) {
    console.error(error);
  }
};
const fetchBulkTransactionStatus = async (userId, transactionIds, apikey) => {
  try {
    if (!userId) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_POST_BULK_TXN_STATUS, {
      method: 'POST',
      body: JSON.stringify({ userId, transactionIds: transactionIds }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json',
        'Priority': 'u=0, i'
      }
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const fechUpdateUserSelectedAsset = async (
  selectedAssetInfo,
  apikey,
  singularityTransactionRequestHash,
  singularityTransactionRequestJsonString,
  userId,
  country,
  currency
) => {
  try {
    if (!selectedAssetInfo) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(
      API_POST_UPDATE_USER_SELECTED_ASSET +
        '?' +
        new URLSearchParams({
          country: country,
          currency: currency
        }),
      {
        method: 'POST',
        body: JSON.stringify(selectedAssetInfo),
        headers: {
          'x-api-key': apikey,
          'x-api-userId': userId,
          'x-api-signature': singularityTransactionRequestHash,
          'data-json-string': singularityTransactionRequestJsonString,
          'Content-Type': 'application/json'
        }
      }
    );
    const updateUserSelectedAssetResponse = await response.json();
    return updateUserSelectedAssetResponse;
  } catch (error) {
    console.error(error);
  }
};

const fetchPaymentOptions = async (requestData, dataHash, apikey, userId, country, currency, signal) => {
  try {
    const response = await fetchWithTid(
      API_GET_PAYMENT_OPTIONS +
        '?' +
        new URLSearchParams({
          country: country,
          currency: currency
        }),
      {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          'x-api-key': apikey,
          'X-api-signature': dataHash,
          'x-api-userId': userId,
          'Content-Type': 'application/json'
        },
        signal: signal
      }
    );
    const res = await response?.json();
    return res;
  } catch (error) {
    if(error && error.name && error.name === 'AbortError') {
      return { aborted: true }
    }
    SingularityLogger.error('Error fetching payment options', error);
  }
};

const getCustomAuthData = async (loginMethod, apikey,customAuthProviderType) => {
  try {
    if (!loginMethod) throw new Error(MISSING_VALID_CONFIG);
    if (!apikey) throw new Error(MISSING_VALID_CONFIG);

    const response = await fetchWithTid(API_GET_CUSTOM_AUTH_DATA, {
      method: 'GET',
      headers: {
        'x-api-key': apikey,
        'x-api-auth-method': loginMethod,
        'x-api-custom-auth-provider-type':customAuthProviderType,
        'Content-Type': 'application/json'
      }
    });
    return await response?.json();
  } catch (error) {
    console.error('Error gettting custom auth data', error);
  }
};
const fetchAuthUserExist = async (bearerToken, apikey, verifierId, loginMethod,customAuthProviderType) => {
  try {
    if (!bearerToken) throw new Error(MISSING_VALID_CONFIG);
    let respJson = await fetchWithTid(
      API_DOES_AUTH_USER_EXIST + '?' +
      new URLSearchParams({
        verifierID: verifierId
      }),
      {
      method: 'GET',
      headers: {
        'x-api-key': apikey,
        authorization: `Bearer ${bearerToken}`,
        'x-api-auth-method': loginMethod,
        'x-api-custom-auth-provider-type':customAuthProviderType,
        'Content-Type': 'application/json'
      }
    });
    respJson = await respJson.json();
    return respJson;
  } catch (error) {
    console.error(error);
  }
};


const getAllAssets = async (apikey) => {
  try {
    if (!apikey) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_GET_ALL_ASSETS, {
      method: 'GET',
      headers: {
        'x-api-key': apikey
      }
    });
    return await response?.json();
  } catch (error) {
    console.error('Error fetching assets', error);
  }
};

const getMoonpaySignature = async (urlForSignature, apikey) => {
  try {
    if (!urlForSignature) throw new Error(MISSING_VALID_CONFIG);
    let respJson = await fetchWithTid(API_GET_MOONPAY_SIGNATURE, {
      method: 'POST',
      body: JSON.stringify({  urlForSignature }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    respJson = await respJson.json();
    return respJson;
  } catch (error) {
    console.error(error);
  }
};


const getCountryCode = async (apikey) => {
  try {
    if (!apikey) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_GET_USER_COUNTRY_CODE, {
      method: 'GET',
      headers: {
        'x-api-key': apikey
      }
    });
    return await response?.json();
  } catch (error) {
    console.error('Error fetching assets', error);
  }
};

const updateTxnStatus = async (singularityTransactionStatus, transactionId, apikey) => {
  try {
    if (!apikey) throw new Error(MISSING_VALID_CONFIG);
    if (!transactionId) throw new Error(MISSING_VALID_CONFIG);
    if (!singularityTransactionStatus) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_UPDATE_TXN_STATUS, {
      method: 'POST',
      body: JSON.stringify({
        singularityTransactionStatus: singularityTransactionStatus,
        transactionId: transactionId
      }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    return await response?.json();
  } catch (error) {
    console.error('Error updateTxnStatus', error);
  }
};

const getTokenBalanceForSupportedChains = async (address, apikey) => {
  try {
    if (!apikey) throw new Error(MISSING_VALID_CONFIG);
    if (!address) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS, {
      method: 'POST',
      body: JSON.stringify({
        ownerAddress: address,
      }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    return await response?.json();
  } catch (error) {
    console.error('Error updateTxnStatus', error);
  }
};
const getNftsSupportedChains = async (address, apikey) => {
  try {
    if (!apikey) throw new Error(MISSING_VALID_CONFIG);
    if (!address) throw new Error(MISSING_VALID_CONFIG);
    const response = await fetchWithTid(API_GET_NFTS_SUPPORTED_CHAINS, {
      method: 'POST',
      body: JSON.stringify({
        user_address: address,
      }),
      headers: {
        'x-api-key': apikey,
        'Content-Type': 'application/json'
      }
    });
    return await response?.json();
  } catch (error) {
    console.error('Error updateTxnStatus', error);
  }
};

const getMessageObj = (event, resp) => {
  return {
    name: event,
    meta: resp
  };
};

export async function invokeService (eventData, callback, signal) {
  const { type, meta } = eventData;
  if (!type || !meta || type !== FETCH_TYPE) {
    return;
  }
  let obj = {};
  const { data } = meta;

  switch (meta.name) {
    case GET_DOES_AUTH_USER: {
      const { token, apikey, verifier, loginMethod,customAuthProviderType } = data;
      obj = getMessageObj(
        GET_DOES_AUTH_USER,
        await fetchAuthUserExist(token, apikey, verifier, loginMethod,customAuthProviderType)
      );
      break;
    }
    case GET_SINGU_TOKEN_BALANCE: {
      const { address, apikey, chainId, chainName, chainDetailsId } = data;
      obj = getMessageObj(
        GET_SINGU_TOKEN_BALANCE,
        await getUserTokenBalances(address, apikey, chainId, chainName, chainDetailsId)
      );
      break;
    }
    case GET_SINGU_USER_NFTS: {
      const { address, chainDetailsId, apikey } = data;
      obj = getMessageObj(GET_SINGU_USER_NFTS, await fetchSingularityUserNFTs(address, chainDetailsId, apikey));
      break;
    }
    case GET_SINGU_WEB3_USER_ACCOUNT: {
      const { web3Data, apikey } = data;
      obj = getMessageObj(
        GET_SINGU_WEB3_USER_ACCOUNT,
        await fetchWeb3UserAccount(web3Data, apikey)
      );
      break;
    }
    case GET_SIGNU_TRANSACTION_STATUS: {
      const { transactionId, apikey } = data;
      obj = getMessageObj(
        GET_SIGNU_TRANSACTION_STATUS,
        await fetchTransactionStatus(transactionId, apikey)
      );
      break;
    }
    case GET_SIGNU_LIVE_PRICE: {
      const {
        transactionObject,
        apikey,
        singularityTransactionRequestHash,
        dataJsonString,
        userId
      } = data;
      obj = getMessageObj(
        GET_SIGNU_LIVE_PRICE,
        await fetchLivePrice(
          transactionObject,
          apikey,
          singularityTransactionRequestHash,
          dataJsonString,
          userId
        )
      );
      break;
    }
    case GET_SIGNU_TRANSACTION_STEPS: {
      const {
        transactionId,
        apikey,
        singularityTransactionRequestHash,
        dataJsonString,
        userId
      } = data;
      obj = getMessageObj(
        GET_SIGNU_TRANSACTION_STEPS,
        await fetchTransactionSteps(
          transactionId,
          apikey,
          singularityTransactionRequestHash,
          dataJsonString,
          userId
        )
      );
      break;
    }
    case GET_SIGNU_TXN_STATUS_BY_USERID: {
      const { userId, apikey, page, size } = data;
      obj = getMessageObj(
        GET_SIGNU_TXN_STATUS_BY_USERID,
        await fetchTransactionStatusByUserId(userId, apikey, page, size)
      );
      break;
    }
    case GET_SIGNU_BULK_TXN_STATUS: {
      const { userId, apikey, transactionIds } = data;
      obj = getMessageObj(
        GET_SIGNU_BULK_TXN_STATUS,
        await fetchBulkTransactionStatus(userId, transactionIds, apikey)
      );
      break;
    }
    case GET_SIGNU_UPDATE_USER_SELETED_ASSET: {
      const {
        selectedAssetObject,
        apikey,
        singularityTransactionRequestHash,
        singularityTransactionRequestJsonString,
        userId,
        country,
        currency
      } = data;
      obj = getMessageObj(
        GET_SIGNU_UPDATE_USER_SELETED_ASSET,
        await fechUpdateUserSelectedAsset(
          selectedAssetObject,
          apikey,
          singularityTransactionRequestHash,
          singularityTransactionRequestJsonString,
          userId,
          country,
          currency
        )
      );
      break;
    }
    case GET_SIGNU_PAYMENT_OPTIONS: {
      const { requestData, dataHash, apikey, userId, country, currency } = data;
      obj = getMessageObj(
        GET_SIGNU_PAYMENT_OPTIONS,
        await fetchPaymentOptions(requestData, dataHash, apikey, userId, country, currency, signal)
      );
      break;
    }
    case GET_CUSTOM_AUTH_DATA: {
      const { loginMethod, apikey,customAuthProviderType } = data;
      obj = getMessageObj(GET_CUSTOM_AUTH_DATA, await getCustomAuthData(loginMethod, apikey,customAuthProviderType));
      break;
    }
    case GET_ALL_ASSETS: {
      const { apikey } = data;
      obj = getMessageObj(GET_ALL_ASSETS, await getAllAssets(apikey));
      break;
    }
    case UPDATE_TXN_STATUS: {
      const { singularityTransactionStatus, transactionId, apikey } = data;
      obj = getMessageObj(UPDATE_TXN_STATUS, await updateTxnStatus(singularityTransactionStatus, transactionId, apikey));
      break;
    }
    case GET_COUNTRY_CODE: {
      const { apikey } = data;
      obj = getMessageObj(GET_COUNTRY_CODE, await getCountryCode(apikey));
      break;
    }
    case GET_MOONPAY_SIGNATURE: {
      const { urlForSignature, apikey } = data;
      obj = getMessageObj(GET_MOONPAY_SIGNATURE, await getMoonpaySignature(urlForSignature, apikey));
      break;
    }
    case GET_TRANSACTIONS_REWARDS: {
      const { transactionId, apikey } = data;
      obj = getMessageObj(GET_TRANSACTIONS_REWARDS, await fetchTransactionRewards(transactionId, apikey));
      break;
    }
    case GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS: {
      const { address, apikey } = data;
      obj = getMessageObj(GET_TOKEN_BALANCES_FOR_SUPPORTED_CHAINS, await getTokenBalanceForSupportedChains(address, apikey));
      break;
    }
    case GET_NFTS_FOR_SUPPORTED_CHAINS: {
      const { address, apikey } = data;
      obj = getMessageObj(GET_NFTS_FOR_SUPPORTED_CHAINS, await getNftsSupportedChains(address, apikey));
      break;
    }

    default:
      break;
  }

  callback({data: obj});
}
